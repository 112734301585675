import styled from "styled-components";
import { Card, Cell, Grid, GridRow, HeadCell, spacing6, spacing8 } from "@litbase/alexandria";
import { forMap } from "@litbase/alexandria/utils/util-functions";
import { T } from "../components/translate-component";
import { Layout } from "../components/layout";

export default function ProductsPage(props) {
  return (
    <Layout {...props}>
      <Body>
        <Content>
          <h1>
            <T>priceList</T>
          </h1>
          <StyledCard $padding={0}>
            <Grid columns="1fr 1fr 1fr">
              <GridRow>
                <HeadCell>
                  <T>name</T>
                </HeadCell>
                <HeadCell>
                  <T>code</T>
                </HeadCell>
                <HeadCell>
                  <T>price</T>
                </HeadCell>
              </GridRow>
              {forMap(0, 10, (index) => (
                <GridRow key={index} $parity={index % 2}>
                  <Cell>Gumitégla</Cell>
                  <Cell>433B</Cell>
                  <Cell>
                    5000 Ft / m<sup>2</sup>
                  </Cell>
                </GridRow>
              ))}
            </Grid>
          </StyledCard>
          <h1>
            <T>delivery</T>
          </h1>
          <DeliveryText>
            <T>deliveryText1</T>
            <br />
            <T>deliveryText2</T>
          </DeliveryText>
        </Content>
      </Body>
    </Layout>
  );
}

const DeliveryText = styled.span`
  max-width: 800px;
  margin: ${spacing8} auto;
`;

const StyledCard = styled(Card)`
  border-radius: 8px;
  overflow: hidden;
  ${HeadCell} {
    z-index: 3;
  }
  margin-bottom: ${spacing8};
`;

const Content = styled.div`
  width: 100%;
  padding: ${spacing6};
`;

const Body = styled.section`
  height: 100%;
  width: 100%;
`;
